import { Component, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'enroll-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnChanges{
  title = 'enroll';
  ngOnChanges(): void {

  }
  ngOnInit(): void {
    
  }
}


