import { UserApp } from './userApp.class';
import { UserCourse } from './userCourse.model';
import { UserSubject } from './userSubject.model';

export class Student extends UserApp {
  UserCourse: UserCourse;
  UserSubject: UserSubject;

  constructor(user?: UserApp, userCourse?: UserCourse, userSubject?: UserSubject) {
    super(user);
    if (userCourse) this.UserCourse = userCourse;
    if (userSubject) this.UserSubject = userSubject;
  }
}
