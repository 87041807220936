import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalsUserService } from 'src/app/services/alerts/modals-user.service';
import { CoursesService } from 'src/app/services/http/courses.service';
import { School } from 'src/app/shared/models/schools/school.model';

@Component({
  selector: 'enroll-exchange-students',
  templateUrl: './exchange-students.component.html',
  styleUrls: ['./exchange-students.component.css'],
})
export class ExchangeStudentsComponent implements OnInit {
  ///Input data for origin selector and lists
  @Input() originFirstSelectData: Array<any> = [];
  @Input() originSecondSelectData: Array<any> = [];
  ///Input data for second selector and lists
  @Input() destinationFirstSelectData: Array<any> = [];
  @Input() destinationSecondSelectData: Array<any> = [];

  ///////LIST TO FILTER AND MOVE
  @Input() originDataList: Array<any> = [];
  ///////LIST TO FILTER AND MOVE
  @Input() destinationDataList: Array<any> = [];

  @Input() originFirstSelectedId: string = null;

  @Output() selectedOriginSecond: EventEmitter<any> = new EventEmitter();
  @Output() selectedOriginFirst: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() selectedDestinationFirst: EventEmitter<any> = new EventEmitter();
  @Output() selectedDestinationSecond: EventEmitter<any> = new EventEmitter();
  ////////////SAVE CHANGES
  @Output() save: EventEmitter<any> = new EventEmitter();

  originSecondSelectedId: string = null;
  destinationFirstSelectedId: string = null;
  destinationSecondSelectedId: string = null;

  allSchoolsList: School[] = [];
  schoolSelected: number = null;

  /////OTHER CONFIGURATION
  @Input() groupsMode: boolean = false;
  @Input() subjectsMode: boolean = false;

  @Input() headerText: string = '';
  @Input() footerText: string = '';

  constructor (
    private _modalsUserService: ModalsUserService,
    private _coursesService: CoursesService,
  ) {
    this._modalsUserService.waitMessage(
      'Cargando',
      'Un momento por favor',
      10000
    );
    this._coursesService.getAllSchools().subscribe( ( schoolsList ) => {
      console.log('schoolsList',schoolsList);
      this.allSchoolsList = schoolsList;
      this._modalsUserService.dismiss();
    });
  }

  ngOnInit(): void {}

  /////////////////ORIGINS
  ///OFFERS ORIGIN
  selectOriginFirst(event) {
    this.selectedOriginFirst.emit(event);
  }
  ///GROUPS/SUBJECTS
  selectOriginSecond(event) {
    this.selectedOriginSecond.emit(event);
  }

  /////////////////DESTINATIONS
  ///School DESTINATION
  selectSchoolChange(event) {
    this.destinationFirstSelectedId = null;
    this.destinationSecondSelectedId = null;
    console.log('schoolSelected',this.schoolSelected);
    this._modalsUserService.waitMessage(
      'Cargando',
      'Un momento por favor',
      10000
    );
    this._coursesService.getCoursesWithOffersBySchool(this.schoolSelected).subscribe( ( coursesList ) => {
      console.log('getCoursesBySchool',coursesList);
      let allOfferList = [];
      coursesList.forEach(courseOfferIterate => {
        allOfferList.push(...courseOfferIterate.CourseOfer);
      });
      console.log('getCoursesBySchool',allOfferList);
      this.destinationFirstSelectData = allOfferList;
      this._modalsUserService.dismiss();
    });
  }
  ///OFFERS DESTINATION
  selectDestinationFirst(event) {
    this.destinationSecondSelectedId = null;
    console.log('selectDestinationFirst',event);
    this.selectedDestinationFirst.emit(event);
  }
  ///GROUPS/SUBJECTS
  selectDestinationSecond(event) {
    this.selectedDestinationSecond.emit(event);
  }

  closeView() {
    this.close.emit('close');
  }

  saveChanges(event) {
    this.save.emit({
      originList: {
        [this.originSecondSelectedId]: event[0],
      },
      destinationList: {
        [this.destinationSecondSelectedId]: event[1],
      },
    });
  }
}
