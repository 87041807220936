import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root',
})
export class ExcelService {
  constructor() {}

  public readFileToJson(file: File, headers?: Array<string>): Promise<any> {
    let arrayBuffer;
    let fileReader = new FileReader();
    let data = fReaderTodata(file);
    return data;

    function fReaderTodata(file) {
      return new Promise((resolve, reject) => {
        fileReader.onload = function found() {
          arrayBuffer = fileReader.result;
          let data = new Uint8Array(arrayBuffer);
          let arr = new Array();
          for (let i = 0; i != data.length; ++i)
            arr[i] = String.fromCharCode(data[i]);
          let bstr = arr.join('');
          let workbook: any = XLSX.read(bstr, { type: 'binary' });
          let first_sheet_name = workbook.SheetNames[0];
          let worksheet = workbook.Sheets[first_sheet_name];

          resolve(
            XLSX.utils.sheet_to_json(worksheet, {
              raw: true,
              defval: null,
              header: headers ? headers : null,
            })
          );
        };
        fileReader.readAsArrayBuffer(file);
      });
    }
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    );
  }
}
