import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { HttpClientModule } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { SharedModule } from './shared/shared.module';
import { SubjectsPageModule } from './pages/subjects/subjects-page.module';
import { StudentsCoursesPageModule } from './pages/manage-students-courses/manage-students-courses-page.module';

@NgModule({
  declarations: [AppComponent, EmptyRouteComponent],
  imports: [
    SharedModule,

    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgSelectModule,
    SweetAlert2Module.forRoot(),
    ////PAGES FOR THIS PROJECT
    SubjectsPageModule,
    StudentsCoursesPageModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
