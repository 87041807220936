import { ReturnStatement } from '@angular/compiler';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SwalComponent, SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';
import { ModalsUserService } from 'src/app/services/alerts/modals-user.service';
import { AuthService } from 'src/app/services/guard/auth.service';
import { CoursesOffersService } from 'src/app/services/http/courses-offers.service';
import { StudentsService } from 'src/app/services/http/students.service';
import { CourseOffer } from 'src/app/shared/models/courses/courseOffer.model';
import { OFERSTATES, TypeOferState } from 'src/app/shared/models/courses/oferState';
import { Subject } from 'src/app/shared/models/subjects/subject.model';
import { SubjectList } from 'src/app/shared/models/subjects/subjects-list.class';
import { UserCourse } from 'src/app/shared/models/users/userCourse.model';
import { UserSubject } from 'src/app/shared/models/users/userSubject.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'enroll-subjects-page',
  templateUrl: './subjects-page.component.html',
  styleUrls: ['./subjects-page.component.css'],
})
export class SubjectsPageComponent implements OnInit {
  @ViewChild('confirmModal') private confirmModal: SwalComponent;

  public userCourses: Array<UserCourse> = [];
  public userCourseSelected: UserCourse;
  public coursesOfferList: Array<CourseOffer> = [];
  public subjectsCourse: SubjectList = new SubjectList();
  public subjectsSelected: SubjectList = new SubjectList();

  public selectedCourse: CourseOffer;
  public idSelectedCourse: number = null;
  public urlViewCalendar: string = '';

  public approvedCredits: number = 0;
  public eTypeOferState = TypeOferState;
  public allSubjectsWithGroupsSelected: boolean = false;
  public subjectsWithGroupsIdsMap;

  private myUserID: number;

  fileToUpload: File;

  public constructor(
    public readonly swalTargets: SwalPortalTargets,
    private modalsUserService: ModalsUserService,
    private coursesOffersService: CoursesOffersService,
    private studentsService: StudentsService,
    private authService: AuthService
  ) {
    this.myUserID = this.authService.getUserId()
  }

  ngOnInit(): void {


    this.coursesOffersService.getByUserId(this.myUserID).subscribe((rs) => {
      if (!rs) return;

      this.userCourses = rs.map(userCourse => {
        userCourse.approvedCredits = 0
        userCourse.approvedCredits = userCourse.coursesOfer.course.Subjects.reduce(
          (acc, subject) => {
            for (let userSubject of subject.userSubjects) {
              if (userSubject.IsAcepted)
                return acc + subject.Creditos
            }
            return acc
          }, 0);

        this.coursesOfferList.push(userCourse.coursesOfer);
        return userCourse;
      });

    });
  }

  public selectCourseOffer(event) {
    const idSelected = event.target.value;
    if (!idSelected || idSelected === null) return;

    this.selectedCourse = this.coursesOfferList.find(
      (courseoffer) => courseoffer.id == idSelected
    );

    this.userCourseSelected = this.userCourses.find(
      usercourse => usercourse.CourseOferID == idSelected
    );

    if (
      !this.selectedCourse ||
      this.selectedCourse.course.Subjects === undefined ||
      this.selectedCourse.course.Subjects.length == 0
    )
      return;

    this.urlViewCalendar =
      '/schedule/academic/' + this.selectedCourse.id;

    this.subjectsSelected = new SubjectList([]);
    this.subjectsCourse = new SubjectList(this.selectedCourse.course.Subjects);
  }

  enrollSubjects() {
    this.confirmModal.dismiss();
    let subjectsArray: Array<string> = Object.values(
      this.subjectsWithGroupsIdsMap
    );

    if (subjectsArray.length == 0) return;
    this.modalsUserService.waitMessage(
      'Inscripción',
      'Realizando la inscripción',
      10000
    );
    this.studentsService
      .enrollSubjects(subjectsArray, this.selectedCourse.id)
      .subscribe((rs) => {
        if (rs.id) {
          this.modalsUserService.alert(
            'Excelente',
            'Se han inscrito las materias seleccionadas.',
            'success'
          );
          this.subjectsSelected = new SubjectList([]);
        } else {
          this.modalsUserService.alert(
            'Error',
            'Se ha presentado un error, por favor intenta más tarde.',
            'error'
          );
          return;
        }
      });
  }

  addSubject(subject) {
    this.subjectsSelected.add(subject);
    this.subjectsSelected = new SubjectList(this.subjectsSelected.getList());
  }

  deleteSubject(subject) {
    this.subjectsSelected.remove(subject);
    this.subjectsSelected = new SubjectList(this.subjectsSelected.getList());
  }

  groupsChanged(groupsSelected) {
    if (!groupsSelected) return;
    this.subjectsWithGroupsIdsMap = groupsSelected;
    this.allSubjectsWithGroupsSelected =
      this.subjectsSelected.getList().length ===
      Object.keys(groupsSelected).length;
  }

  getOfferStateName(offerState: number) {
    if (offerState === undefined) return '';
    return OFERSTATES[offerState].nameState;
  }

  
  uploadFileDocuments(propierty,fileInput: any) {

    this.fileToUpload = <File>fileInput.target.files[0];

    if (this.fileToUpload.size > 5000000) {//5M
      console.error("error de archivo")
    }
    else {
      this.studentsService.NewDocumentsInfoSuports(this.fileToUpload).then((result) => {

        //this.studentsService.updateUserCourse()
       let dataCourse = {}
       dataCourse[propierty]="/attachments/documentsinfo/download/" + result['name'];
       
       this.studentsService.updateUserCourse(this.userCourseSelected.id,dataCourse ).subscribe( data => {
         this.userCourseSelected[propierty]=data[propierty];
       })
 
      }, (error) => {
        console.error(error);
      })
    }
  }

  uploadFileDocumentsPersonal(propierty,fileInput: any) {

    this.fileToUpload = <File>fileInput.target.files[0];

    if (this.fileToUpload.size > 5000000) {//5M
      console.error("error de archivo")
    }
    else {
      this.studentsService.NewDocumentsInfoSuports(this.fileToUpload).then((result) => {

        //this.studentsService.updateUserCourse()
       let dataUserdocument = {}
       dataUserdocument[propierty]="/attachments/documentsinfo/download/" + result['name'];

       
       this.studentsService.updateUserDocument(this.userCourseSelected.userapp.UserDocuments[0].id,dataUserdocument ).subscribe( data => {
         this.userCourseSelected.userapp.UserDocuments[0][propierty]=data[propierty];
       })
 
      }, (error) => {
        console.error(error);
      })
    }
  }

  /**
   * Obtiene una URL completa para descargar un archivo PDF específico.
   *
   * @param urlComplement - Una cadena que representa la URL base o parcial.
   * @returns Una cadena que representa la URL completa del archivo PDF.
   */
  getCompleteUrlFile(urlComplement: string = ''): string {
    let url = `${environment.fileBaseUrl}/${urlComplement}`;
    return url;
  }
}
