import { Subject } from './subject.model';

export class SubjectList {
  subjects: Array<Subject> = [];
  totalPrice: number = 0;
  totalHours: number = 0;
  totalCredits: number = 0;
  constructor(subjects?: Partial<Array<Subject>>) {
    this.subjects = subjects || [];
    this.setTotals();
  }
  private setTotals() {
    let totals = this.subjects.reduce(
      (acc, subject: Subject) => {
        acc.totalPrice += isNaN(subject.price) ? 0 : subject.price;
        acc.totalHours += isNaN(subject.HPM) ? 0 : subject.HPM;
        acc.totalCredits += isNaN(subject.Creditos) ? 0 : subject.Creditos;
        return acc;
      },
      { totalCredits: 0, totalHours: 0, totalPrice: 0 }
    );

    this.totalCredits = totals.totalCredits;
    this.totalHours = totals.totalHours;
    this.totalPrice = totals.totalPrice;
  }

  add(subject: Subject) {
    this.subjects.push(subject);
    this.setTotals();
    return this.subjects;
  }

  remove(subject: Subject) {
    this.subjects = this.subjects.filter((sub) => sub.id !== subject.id);
    this.setTotals();
    return this.subjects;
  }

  getList() {
    return this.subjects;
  }
}
