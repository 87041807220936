import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../guard/auth.service';
import { catchError } from 'rxjs/internal/operators/catchError';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  public urlHttp: string = environment.baseUrl;
  public urlHttpBackV1: string = environment.fileBaseUrl;

  constructor(private http: HttpClient, private authService: AuthService) {}

  getHeader(): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: this.authService.getToken(),
    });
    return headers;
  }

  httpGet(url: string): any {
    let headers = this.getHeader();
    return this.http.get(this.urlHttp + url, { headers: headers });
  }

  httpGetBackV1(url: string): any {
    let headers = this.getHeader();
    return this.http.get(this.urlHttpBackV1 + url, { headers: headers });
  }

  httpPost(url: string, data: any): any {
    return this.http.post(this.urlHttp + url, data, {
      headers: this.getHeader(),
    }).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
           return `Error: ${error.error.message}`;
        } else {
          return  `Error: ${error}`;
        }
     
    })
    )
  }

  httpDelete(url: string): any {
    return this.http.delete(this.urlHttp + url, { headers: this.getHeader() });
  }

  httpPatch(url: string, data: any): any {
    return this.http.patch(this.urlHttp + url, data, {
      headers: this.getHeader(),
    });
  }

  httpPatchBackV1(url: string, data: any): any {
    return this.http.patch(this.urlHttpBackV1 + url, data, {
      headers: this.getHeader(),
    });
  }

  httpPut(url: string, data: any): any {
    return this.http.put(this.urlHttp + url, data, {
      headers: this.getHeader(),
    });
  }

  httpPostFile(url: string, file: File){
    return new Promise((resolve, reject) => {
  
      var xhr = new XMLHttpRequest();
      var fd = new FormData();
  
      xhr.open("POST", this.urlHttp + url, true);
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          var res = JSON.parse(xhr.responseText)
          resolve(res.result.files.file[0]);
        }
      };
  
      fd.append('file', file);
      //fd.append('data', JSON.stringify(fileAdj));
      xhr.setRequestHeader('Authorization', this.authService.getToken())
      xhr.send(fd);
  
    });
  }

}
