import { AcademicInfo } from './academicInfo.model';
import { MilitarInfo, UserDocument } from './documents.model';
import { ProfessionalProfile } from './professionalProfile.model';
import { UserClasification } from './userClasification.model';
import { UserContacts } from './userContacts.model';

export class UserApp {
  id: number;
  CedocEmail: string;
  email: string;

  Name1: string;
  Name2: string;
  LastName1: string;
  LastName2: string;
  Photo: string;
  TimeCreate: string;

  IsMilitar?: boolean;
  IsTemporal?: boolean;
  roleMappings?: any[];
  MilitarInfos?: Array<MilitarInfo>;
  UserDocuments?: Array<UserDocument>;
  ClassificationUser?: Array<UserClasification>;
  ContactInfos?: Array<UserContacts>;
  AcademicInfos?: Array<AcademicInfo>;
  ProfesionalProfile?: Array<ProfessionalProfile>;

  constructor(userData?: Partial<UserApp>) {
    Object.assign(this, userData);
  }

  public getConcatenatedName(): string {
    const { Name1, Name2, LastName1, LastName2 } = this;
    return (Name1 + ' ' + Name2 + ' ' + LastName1 + ' ' + LastName2).replace(
      /null/g,
      ''
    );
  }
  public getConcatenatedFirstName(): string {
    const { Name1, Name2 } = this;
    return (Name1 + ' ' + Name2).replace(
      /null/g,
      ''
    );
  }
  public getConcatenatedLastName(): string {
    const { LastName1, LastName2 } = this;
    return (LastName1 + ' ' + LastName2).replace(
      /null/g,
      ''
    );
  }
  public getPersonalEmail(): string {
    const { ContactInfos } = this;
    let personalEmail = 'N/A';
    if (
      ContactInfos &&
      ContactInfos.length > 0 &&
      ContactInfos[0].Email
    ) personalEmail = ContactInfos[0].Email;
    return (personalEmail).replace(/null/g,'');
  }

  public getInitialsName(): string {
    const { Name1, Name2 } = this;
    return (
      (Name1 ? Name1.trim().charAt(0).toUpperCase() : '') +
      (Name2 ? Name2.trim().charAt(0).toUpperCase() : '')
    );
  }

  public getCivilDocument(): string {
    if (!this.UserDocuments || this.UserDocuments.length === 0) return 'N/A';
    // let rs = this.UserDocuments.filter(
    //   (doc: UserDocument) => doc.TypeDocumentID === 1
    // );
    // if (rs.length === 0) return 'N/A';
    // return rs[0].Document;
    return this.UserDocuments[0].Document;
  }
  public getTypeDocument(): string {
    if (!this.UserDocuments || this.UserDocuments.length === 0) return 'N/A';
    // let rs = this.UserDocuments.filter(
    //   (doc: UserDocument) => doc.TypeDocumentID === 1
    //   );
    //   if (rs.length === 0) return 'N/A';
    return this.UserDocuments[0].TypeDocuments.NameTypeDocument;
  }
  public getMilitarDocument(): string {
    if (!this.MilitarInfos || this.MilitarInfos.length === 0) return 'N/A';
    let militarNumber = this.MilitarInfos[0].CedulaMil || 'N/A';
    return militarNumber;
  }

  public getInitialsRange(): string {
    if (
      !this.MilitarInfos ||
      this.MilitarInfos.length === 0 ||
      !this.MilitarInfos[0].Range ||
      !this.MilitarInfos[0].Range.Sigla
    )
      return '';

    return this.MilitarInfos[0].Range.Sigla;
  }
}
