import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import { Subject } from 'src/app/shared/models/subjects/subject.model';
import { SubjectList } from 'src/app/shared/models/subjects/subjects-list.class';
import { UserApp } from 'src/app/shared/models/users/userApp.class';

///subjectId & groupId
export interface SubjectsGroupSelected {
  [subjectId: string]: string;
}

@Component({
  selector: 'enroll-table-subjects-selected',
  templateUrl: './table-subjects-selected.component.html',
  styleUrls: ['./table-subjects-selected.component.css'],
})
export class TableSubjectsSelectedComponent implements OnInit, OnChanges {
  @Input() readOnly: boolean = false;
  @Output()
  groupsChanged: EventEmitter<SubjectsGroupSelected> = new EventEmitter();

  idGroupSelected: number = null;
  subjectsList: SubjectList = null;

  allGroupsSelected: boolean = false;
  subjectsGroupSelected: SubjectsGroupSelected = {};

  constructor() {}
  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.subjects.currentValue)
      this.configureGroups(), this.emitSubjectsAndGroups();
  }

  @Input()
  set subjects(subjectsList: SubjectList) {
    this.subjectsList = subjectsList;
  }

  @Input()
  set groupsSelected(groups: SubjectsGroupSelected) {
    if (Object.keys(groups).length == 0) return;
    this.subjectsGroupSelected = groups;
  }

  selectGroup(subject: Subject, event) {
    const subjectGroupId = event.target.value;
    subjectGroupId !== null && subjectGroupId !== ''
      ? (this.subjectsGroupSelected[subject + ''] = subjectGroupId)
      : delete this.subjectsGroupSelected[subject + ''];
    this.emitSubjectsAndGroups();
  }

  emitSubjectsAndGroups() {
    if (Object.keys(this.subjectsGroupSelected).length == 0) return;
    this.groupsChanged.emit(this.subjectsGroupSelected);
  }

  getTeacherName(subject: Subject): string {
    if (!this.subjectsGroupSelected[subject.id + '']) return 'N/D';
    let group = subject.subjectgroup.find(
      (group) => group.id + '' == this.subjectsGroupSelected[subject.id + '']
    );
    if (!group || !group.userapp) return 'N/D';
    return new UserApp(group.userapp).getConcatenatedName();
  }

  getGroupName(subject: Subject): string {
    if (!subject || !this.subjectsGroupSelected[subject.id + '']) return 'N/D';
    let group = subject.subjectgroup.find(
      (group) => group.id + '' == this.subjectsGroupSelected[subject.id + '']
    );
    if (!group) return 'N/D';
    return group.NameSubjectGroup;
  }

  configureGroups() {
    Object.keys(this.subjectsGroupSelected).forEach((subjectId) => {
      const idx = this.subjectsList
        .getList()
        .findIndex((subject) => subject.id.toString() === subjectId);
      idx < 0 ? delete this.subjectsGroupSelected[subjectId] : '';
    });
    this.subjectsList.getList().forEach((subject: Subject) => {
      if (subject.subjectgroup && subject.subjectgroup.length == 1)
        if (
          subject.subjectgroup[0].id &&
          !this.subjectsGroupSelected[subject.id]
        )
          this.subjectsGroupSelected[subject.id + ''] =
            subject.subjectgroup[0].id + '';
    });
  }
}
