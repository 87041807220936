import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject, SubjectState } from 'src/app/shared/models/subjects/subject.model';

@Component({
  selector: 'enroll-card-subject-select',
  templateUrl: './card-subject-select.component.html',
  styleUrls: ['./card-subject-select.component.css'],
})
export class CardSubjectSelectComponent implements OnInit {
  @Input() subject: Subject;
  @Input() selectionDisabled: boolean = false;

  @Output() select: EventEmitter<Subject> = new EventEmitter();
  @Output() unSelect: EventEmitter<Subject> = new EventEmitter();
  actionList = ['Homologar', 'Inscribir'];
  actionSelected: string = null;
  public enSubjectState = SubjectState;
  constructor() { }

  ngOnInit(): void { }

  ///Notify to its parent component if subject was
  //selected or unselected
  selection(event) {
    if (event.currentTarget.checked) this.select.emit(this.subject);
    if (!event.currentTarget.checked) this.unSelect.emit(this.subject);
  }

  getSubjectStateForUser(subject: Subject): SubjectState {
    if (!subject.subjectgroup) 
    { 
      return SubjectState.pendiente 
    }
    else {
      if (!subject.userSubjects)
        return SubjectState.encurso
      else if(subject.userSubjects.length > 0){
        if (subject.userSubjects[0].IsAproved && !subject.userSubjects[0].IsHomolog) {
          return SubjectState.aprobado
        }
        else if (subject.userSubjects[0].IsAproved && subject.userSubjects[0].IsHomolog) {
          return SubjectState.homologado
        }
        else {
          return SubjectState.noaprobado
        }
      }
      else {
        return SubjectState.pendiente 
      }
    }
    return SubjectState.pendiente 
    //
  }
}
