import { NgModule } from '@angular/core';

import { SubjectsPageComponent } from './subjects-page.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule, Routes } from '@angular/router';
import { CoursesOffersService } from 'src/app/services/http/courses-offers.service';
import { CardSubjectSelectComponent } from 'src/app/shared/components/subjects/card-subject-select/card-subject-select.component';
import { TableSubjectsSelectedComponent } from 'src/app/shared/components/subjects/table-subjects-selected/table-subjects-selected.component';
import { OrderByPipe } from 'src/app/shared/pipes/order-by.pipe';

const routes: Routes = [{ path: '', component: SubjectsPageComponent }];

@NgModule({
  declarations: [
    CardSubjectSelectComponent,
    TableSubjectsSelectedComponent,
    SubjectsPageComponent,
    OrderByPipe
  ],
  imports: [SharedModule, RouterModule.forChild(routes)],
  providers: [CoursesOffersService],
})
export class SubjectsPageModule {}
