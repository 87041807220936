import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { take } from 'rxjs/operators';

import { UserCourse } from 'src/app/shared/models/users/userCourse.model';
import { HttpService } from './http.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CoursesOffersService {
  constructor(private _httpService: HttpService, private _http:HttpClient) { }

  getByUserId(id:number): Observable<Array<UserCourse>> {
    let filter = {
      "include": [
        {"userapp":"UserDocuments"},
        {
          "relation": "coursesOfer",
          "scope":
          {
            "include": [
            {
              "relation": "course",
              "scope":
              {
                "include": {
                  "relation": "Subjects",
                  "scope":
                  {
                    "include": [{
                      "relation": "subjectgroup",
                      "scope":
                        { "where": { "IsActive": 1 }, "include": "userapp" }
                    },
                    {
                      "relation": "userSubjects",
                      "scope":
                        { "where": { "UserID": id }, "include": "subjectGroup" }
                    }]
                  }
                }
              }
            },
            {
              "relation": "TypeCourseOFer"
            },
            {
              "relation": "modality"
            }]
          }
        }
      ]

    };

    return this._httpService
      .httpGet('Userapps/me/UserCourses?filter=' + JSON.stringify(filter))
      .pipe(take(1));
  }

  getCourseGroups(idOffer: number): Observable<any> {
    if (!idOffer) return of(null);
    const url = `/CoursesOfers/${idOffer}/coursegroups`;
    return this._httpService.httpGet(url).pipe(take(1));
  }

  getSubjectGroups(filter?: string): Observable<any> {
    let url: string = `/SubjectGroups`
    if (!!filter)
      url = url + "?filter=" + filter

    return this._httpService.httpGet(url).pipe(take(1));
  }

  ////////////////////////VALIDATION OAP
  uploadToValidationOAP(idOffer: number, data: any[]) {
    if (!idOffer || data.length === 0) return;
    const url = `CoursesOfers/${idOffer}/validationoaps`;
    return this._httpService.httpPost(url, data).pipe(take(1));
  }

  getValidationOAP(idOffer: number): Observable<Array<any>> {
    if (!idOffer) return;
    const url = `CoursesOfers/${idOffer}/validationoaps`;
    return this._httpService.httpGet(url).pipe(take(1));
  }

  getTotalValidationOAP(idOffer: number): Observable<any> {
    if (!idOffer) return;
    const url = `CoursesOfers/${idOffer}/validationoaps/count`;
    return this._httpService.httpGet(url).pipe(take(1));
  }
  deleteListValidationOAP(idOffer: number): Observable<any> {
    if (!idOffer) return of(null);
    const url = `/CoursesOfers/${idOffer}/validationoaps`;
    return this._httpService.httpDelete(url).pipe(take(1));
  }

  updateStudentRole(userId:number, roleMappingId:number, data:any){
    const url = `/Userapps/${userId}/roleMappings/${roleMappingId}`;
    return this._httpService.httpPut(url, data);
  }

  //Wb Google Workspace - create user

  updateWsUser(data:any){
    const url = `${environment.googleWsApi}/update-user`
    return this._http.put(url, data);
  }

  createWsUser(data:any){
    const url = `${environment.googleWsApi}/create-user`
    return this._http.post(url, data);
  }
}
