import { schoolToLoad } from "./environment.common";

export const environment = {
  production: false,
  baseUrl: 'https://test-default-dot-cedoc-360c.ue.r.appspot.com/api/',
  fileBaseUrl: 'https://test-user-dot-cedoc-360c.ue.r.appspot.com/api', /** URL BACK v1 */
  googleWsApi: 'https://us-central1-cedoc-360c.cloudfunctions.net/dev-consolWorkspaceClientApi',
  principalAppName: 'cedoc',

  ...schoolToLoad()
};
