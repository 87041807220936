export class OferState {
    public id:number;
    public nameState:string;
    public color:string;

    public totalCourse?:number = 0;
}

export enum TypeOferState {
    pendiente = 0,
    aprobado = 1,
    reprogramado = 3,
    cancelado = 4,
    encurso = 5,
    encursoOk = 6,
    finalizado = 7,
    atraso = 8,
    noCronograma = 9 
}


export const OFERSTATES = {
    [TypeOferState.pendiente] : {id:TypeOferState.pendiente,nameState:"Pendiente Aprobacion",color:"#F8B974"},
    [TypeOferState.aprobado] : {id:TypeOferState.aprobado,nameState:"Aprobado Cronograma",color:"#95D241"},
    [TypeOferState.reprogramado] : {id:TypeOferState.reprogramado,nameState:"Reprogramado",color:"#6F57E9"},
    [TypeOferState.cancelado] : {id:TypeOferState.cancelado,nameState:"Cancelado",color:"#D01414"},
    [TypeOferState.encurso] : {id:TypeOferState.encurso,nameState:"En curso - PTE Lista Alumnos",color:"#FF6224"},
    [TypeOferState.encursoOk] : {id:TypeOferState.encursoOk,nameState:"En Curso",color:"#14D0C9"},
    [TypeOferState.finalizado] : {id:TypeOferState.finalizado,nameState:"Finalizado",color:"#14D0C9"},
    [TypeOferState.atraso] : {id:TypeOferState.atraso,nameState:"Curso en Atraso",color:"#6F57E9"},
    [TypeOferState.noCronograma] : {id:TypeOferState.noCronograma,nameState:"Fuera de Cronograma",color:"#6F57E9"}
}





