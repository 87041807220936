import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { BackgroundForceDirective } from './directives/background-force.directive';

@NgModule({
  declarations: [BackgroundForceDirective],
  imports: [CommonModule, FormsModule],
  exports: [
    CommonModule,
    FormsModule,
    SweetAlert2Module,
    BackgroundForceDirective,
  ],
})
export class SharedModule {}
