import Swal from "sweetalert2";

const swalError = ({ title = 'Error', message, confirmCallback, cancelCallback,  html }: { title?: string, message?: string, confirmCallback?: () => void, cancelCallback?: () => void, html?:string }) => {
  Swal.fire({
    icon: 'error',
    title: title,
    text: message,
    html,
    showConfirmButton: true,
    allowEscapeKey: false,
    allowOutsideClick: false,
  }).then((result) => {
    if (result.isConfirmed && confirmCallback) {
      confirmCallback();
    } else if (result.isDismissed && cancelCallback) {
      cancelCallback();
    } else {
      Swal.close();
      return;
    }
  });
}

const swalSuccess = ({ title = 'Hecho!', message, confirmCallback, cancelCallback, html }: { title?: string, message?: string, confirmCallback?: () => void, cancelCallback?: () => void, html?:string }) => {
  Swal.fire({
    icon: 'success',
    title: title,
    text: message,
    html,
    showConfirmButton: true,
    allowEscapeKey: false,
    allowOutsideClick: false,
  }).then((result) => {
    if (result.isConfirmed && confirmCallback) {
      confirmCallback();
    } else if (result.isDismissed && cancelCallback) {
      cancelCallback();
    } else {
      Swal.close();
      return;
    }
  })
}

const swalConfirm = ({ title = 'Hecho!', message, confirmCallback, cancelCallback, html }: { title?: string, message?: string, confirmCallback?: () => void, cancelCallback?: () => void, html?:string }) => {
  Swal.fire({
    icon: 'question',
    title: title,
    text: message,
    html,
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: "Confirmar",
    cancelButtonText: "Cancelar",
    confirmButtonColor: '#0190fe',
    cancelButtonColor: '#d33',
    allowEscapeKey: false,
    allowOutsideClick: false,
  }).then((result) => {
    if (result.isConfirmed && confirmCallback) {
      confirmCallback();
    } else if (result.isDismissed && cancelCallback) {
      cancelCallback();
    } else {
      Swal.close();
      return;
    }
  })
}

const swalLoading = ({ title = 'Cargando...', message }: { title?: string, message?: string }) => {
  Swal.fire({
    title: title,
    text: message,
    showConfirmButton: false,
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
  Swal.showLoading()
}

const swalWarning = ({ title, message, confirmCallback, cancelCallback, confirmButtonText = 'Ok', cancelButtonText = 'Cancel' }: { title?: string, message?: string, confirmCallback?: () => void, cancelCallback?: () => void, confirmButtonText?: string, cancelButtonText?: string }) => {
  Swal.fire({
    icon: 'warning',
    title: title,
    text: message,
    showConfirmButton: true,
    showCancelButton: true,
    allowEscapeKey: false,
    allowOutsideClick: false,
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
  }).then((result) => {
    if (result.isConfirmed && confirmCallback) {
      confirmCallback();
    } else if (result.isDismissed && cancelCallback) {
      cancelCallback();
    } else {
      Swal.close();
      return;
    }
  })
}
const swalUpdateLoading = ({ title, message }: { title?: string, message?: string }) => {
  Swal.update({ title, text: message })
  Swal.showLoading();
}

const swalClose = () => {
  Swal.close()
}

export { swalError, swalSuccess, swalLoading, swalWarning, swalUpdateLoading, swalClose, swalConfirm }