import { UserSubject } from '../users/userSubject.model';
import { SubjectGroup } from './subjectGroup.model';

export interface Subject {
  NameSubject: string;
  Description: string;
  id: number;
  AcademicAreaID: number;
  HFH: number; ///horas fuera de horario
  HPM: number; ///horas practica materia
  HTI: number; /////horas táctica
  Creditos: number;
  GPA: number; ///promedio general
  price?: number;
  state?: number;
  SemesterNumber?: number;
  subjectgroup?: Array<SubjectGroup>;
  userSubjects?: Array<UserSubject>;
  
}

export enum SubjectState{
    pendiente = "Pendiente",
    encurso = "En Curso",
    aprobado = "Aprobado",
    noaprobado = "No Aprobado",
    prerequisito = "Prerequisito",
    homologado = "Homologado"
}
