import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon, SweetAlertResult } from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class ModalsUserService {
  constructor() {}

  ///types: ['success','error','question','info','warning']
  alert(
    title?: string,
    text?: string,
    icon?: SweetAlertIcon,
    confirmButtonText?
  ) {
    Swal.fire({
      icon: icon ? icon : 'info',
      title: title,
      text: text,
      showCloseButton: confirmButtonText == null ? true : false,
      showConfirmButton: confirmButtonText == null ? false : true,
      confirmButtonText: confirmButtonText,
    });
  }

  confirm(
    title?: string,
    msgHtml?: string,
    confirmButtonText?,
    cancelButtonText?: string,
    confirmColor?: string,
    cancelColor?: string
  ): Promise<SweetAlertResult> {
    return Swal.fire({
      icon: 'question',
      title: title ? title : '',
      html: msgHtml ? msgHtml : '',
      confirmButtonText: confirmButtonText ? confirmButtonText : 'Confirmar',
      cancelButtonText: cancelButtonText ? cancelButtonText : 'Cancelar',
      confirmButtonColor: confirmColor ? confirmColor : '#0190fe',
      cancelButtonColor: cancelColor ? cancelColor : '#d33',
      showCancelButton: true,
    });
  }

  confirmWithSuccessAlert (
    title?: string,
    msgHtml?: string,
    confirmButtonText?: string,
  ): Promise<SweetAlertResult> {
    return Swal.fire({
      icon: 'success',
      title: title ? title : '',
      html: msgHtml ? msgHtml : '',
      confirmButtonText: confirmButtonText ? confirmButtonText : 'Confirmar',
      confirmButtonColor: '#0190fe',
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });
  }

  waitMessage(
    title?: string,
    msgHtml?: string,
    timer?: number
  ): Promise<SweetAlertResult> {
    return Swal.fire({
      icon: 'info',
      title: title ? title : 'Estamos procesando su petición',
      html: msgHtml
        ? msgHtml
        : 'Esta ventana se cerrara automaticamente, Espere por favor ...',
      timer: timer ? timer : 1500,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }

  infiniteWaitMessage(
    title: string,
    msgHtml: string
  ): Promise<SweetAlertResult> {
    return Swal.fire({
      icon: 'info',
      title: title,
      html: msgHtml,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showCancelButton: false,
      showCloseButton: false,
      showConfirmButton: false,
    });
  }


  loadingMessage(
    title?: string,
    msgHtml?: string,
  ): Promise<SweetAlertResult> {
    return Swal.fire({
      icon: 'info',
      title: title ? title : 'Estamos procesando su petición',
      html: msgHtml
        ? msgHtml
        : 'Esta ventana se cerrara automaticamente, Espere por favor ...',
      onBeforeOpen: () => {
        Swal.showLoading();
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }

  close(){
    Swal.close();
  }

  editUser(
    title: string,
    callBack: Function,
    event?:any,
  ){
    Swal.fire({
      title: title,
      showCancelButton: true,
      confirmButtonText: 'Guardar',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      width: '800px',
      html:
      `<div class="row">
        <div class="col">
          <div>
            <label for="swal-name1" class="text-left d-block">Primer nombre</label>
            <input type="text" id="swal-name1" class="form-control mt-0" placeholder="Primer nombre" value="${event.Name1}">
          </div>

          <div class="mt-4">
            <label for="swal-lastName1" class="text-left d-block">Primer apellido</label>
            <input type="text" id="swal-lastName1" class="form-control" placeholder="Primer apellido" value="${event.LastName1}">
          </div>
        </div>

        <div class="col">
          <div>
            <label for="swal-name2" class="text-left d-block">Segundo nombre</label>
            <input type="text" id="swal-name2" class="form-control" placeholder="Segundo nombre" value="${event.Name2 || ''}">
          </div>

          <div class="mt-4">
            <label for="swal-lastName2" class="text-left d-block">Segundo apellido</label>
            <input type="text" id="swal-lastName2" class="form-control" placeholder="Segundo apellido" value="${event.LastName2 || ''}">
          </div>
        </div>

        <div class="col">
          <div>
            <label for="swal-document" class="text-left d-block">Documento</label>
            <input type="text" id="swal-document" class="form-control" placeholder="Documento" value="${event.UserDocuments[0].Document}">
          </div>

          <div class="mt-4">
            <label for="swal-cellphone" class="text-left d-block">Celular</label>
            <input type="text" id="swal-cellphone" class="form-control" placeholder="Celular" value="${event.ContactInfos[0].CellPhone}">
          </div>
        </div>
      </div>`,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: () => {
        const name1 = ( Swal.getPopup().querySelector( '#swal-name1' ) as HTMLInputElement ).value;
        const name2 = ( Swal.getPopup().querySelector( '#swal-name2' ) as HTMLInputElement ).value;
        const lastName1 = ( Swal.getPopup().querySelector( '#swal-lastName1' ) as HTMLInputElement ).value;
        const lastName2 = ( Swal.getPopup().querySelector( '#swal-lastName2' ) as HTMLInputElement ).value;
        const document = ( Swal.getPopup().querySelector( '#swal-document' ) as HTMLInputElement ).value;
        const cellphone = ( Swal.getPopup().querySelector( '#swal-cellphone' ) as HTMLInputElement ).value;

        return { name1, name2, lastName1, lastName2, document, cellphone };
      }
    }).then((result)=>{
      if (result.isConfirmed) {
        callBack( result.value );
      }
    })
  }

  dismiss() {
    Swal.close();
  }
}
