import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Student } from 'src/app/shared/models/users/student.class';
import { StudentListComponent } from '../student-list/student-list.component';

interface TransactionList {
  [idObject: string]: 'fromOrigin' | 'fromDestination';
}

@Component({
  selector: 'enroll-manage-students-list',
  templateUrl: './manage-students-list.component.html',
  styleUrls: ['./manage-students-list.component.css'],
})
export class ManageStudentsListComponent implements OnInit {
  @Input() originStudentsList: Array<Student> = [];
  @Input() destinationStudentsList: Array<Student> = [];
  @Output() cancelFromView: EventEmitter<boolean> = new EventEmitter();
  @Output() sendChanges: EventEmitter<Array<Student[]>> = new EventEmitter();

  addedDestiniationList: Array<Student> = [];
  addedOriginList: Array<Student> = [];
  transactionsCache: TransactionList = {};

  @ViewChild('originList')
  originList: StudentListComponent;
  @ViewChild('destinationList')
  destinationList: StudentListComponent;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes['firstChange']) {
      this.addedOriginList = [];
      this.destinationStudentsList = [];
    }
  }

  cancel() {
    this.cancelFromView.emit(true);
  }

  droppedOriginList(event) {
    if (event.previousContainer === event.container) {
      ///////Change the position inside the same array
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      ///////Change element to other array
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      let studentMoved: Student = event.container.data[event.currentIndex];
      this.addedDestiniationList = this.cleanAddedList(
        this.addedDestiniationList,
        studentMoved
      );

      if (
        this.transactionsCache[studentMoved.id] &&
        this.transactionsCache[studentMoved.id] === 'fromOrigin'
      )
        return;
      this.addedOriginList.push(studentMoved);
      this.transactionsCache[studentMoved.id] = 'fromDestination';
    }
  }

  droppedDestinationList(event) {
    if (event.previousContainer === event.container) {
      ///////Change the position inside the same array
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      ///////Change element to other array
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      let studentMoved: Student = event.container.data[event.currentIndex];

      this.addedOriginList = this.cleanAddedList(
        this.addedOriginList,
        studentMoved
      );
      if (
        this.transactionsCache[studentMoved.id] &&
        this.transactionsCache[studentMoved.id] === 'fromDestination'
      )
        return;
      this.addedDestiniationList.push(event.container.data[event.currentIndex]);
      this.transactionsCache[studentMoved.id] = 'fromOrigin';
    }
  }

  ///When  users use checkbox to move students
  moveToOrigin() {
    this.destinationList.checkedStudentsList.forEach((st) => {
      ///Clean origin data
      this.originStudentsList = this.cleanAddedList(
        this.originStudentsList,
        st
      );
      this.originStudentsList.unshift(st);
      this.originStudentsList = [...this.originStudentsList];
      ///Clean destination data
      this.addedDestiniationList = this.cleanAddedList(
        this.addedDestiniationList,
        st
      );
      this.destinationStudentsList = this.cleanAddedList(
        this.destinationStudentsList,
        st
      );
      this.destinationStudentsList = [...this.destinationStudentsList];
      ///Check cache transactions
      if (
        this.transactionsCache[st.id] &&
        this.transactionsCache[st.id] === 'fromOrigin'
      )
        return;
      ///Add to list for write
      this.addedOriginList.push(st);
      this.transactionsCache[st.id] = 'fromDestination';
    });
    this.destinationList.unCheckAll();
  }

  ///When users use checkbox to move students
  moveToDestination() {
    this.originList.checkedStudentsList.forEach((st) => {
      ///Clean destination data
      this.destinationStudentsList = this.cleanAddedList(
        this.destinationStudentsList,
        st
      );
      this.destinationStudentsList.unshift(st);
      this.destinationStudentsList = [...this.destinationStudentsList];
      ///Clean origin data
      this.addedOriginList = this.cleanAddedList(this.addedOriginList, st);
      this.originStudentsList = this.cleanAddedList(
        this.originStudentsList,
        st
      );
      this.originStudentsList = [...this.originStudentsList];
      ///Check cache transactions
      if (
        this.transactionsCache[st.id] &&
        this.transactionsCache[st.id] === 'fromDestination'
      )
        return;
      ///Add to list for write
      this.addedDestiniationList.push(st);
      this.transactionsCache[st.id] = 'fromOrigin';
    });
    this.originList.unCheckAll();
  }

  cleanAddedList(list: Array<Student>, student: Student) {
    let idx = list.findIndex((st) => st.id === student.id);
    if (idx >= 0) list.splice(idx, 1);
    return list;
  }

  saveChanges() {
    this.sendChanges.emit([this.addedOriginList, this.addedDestiniationList]);
  }

  resetState() {
    this.addedOriginList = [];
    this.addedDestiniationList = [];
    this.transactionsCache = {};
  }
}
