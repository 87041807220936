import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { RoleMapping } from 'src/app/shared/models/users/roleMapping.model';
import { Student } from 'src/app/shared/models/users/student.class';
import { HttpService } from './http.service';

interface updateStudentGroup {
  id: number; //CourseGroupIdOrign
  CourseGroupID: number; //CourseGroupIdDestination
  UserID: number;
}

@Injectable({
  providedIn: 'root',
})
export class StudentsService {
  constructor(private _httpService: HttpService) {}

  getFromCourseOfferId(id): Observable<Array<Student>> {
    const filter = {
      where: { IsTransferred: false },
      include: {
        userapp: [
          {roleMappings: 'school'},
          'ContactInfos',
          'ProfesionalProfile',
          {
            ClassificationUser: [
              'typeBiologicalSex',
              'typeCivilStatus',
              'typeEthnicalGroup',
              'typeIndigeniusPeople',
              'typeCommunities',
              'typeHandicapped',
              'typeExceptionalCapacity',
              'typeZone',
              'typeSisben',
              'typeEstrato',
              'typeRH',
            ],
          },
          { AcademicInfos: 'TypeCourse' },
          { UserDocuments: 'TypeDocuments' },
          { MilitarInfos: ['Range', 'Force'] },
        ],
      },
    };

    return this._httpService
      .httpGet(
        `CoursesOfers/${id}/UserCourses?filter=` + JSON.stringify(filter)
      )
      .pipe(
        take(1),
        map((rs: any) => {
          if (!rs || rs.length == 0) return [];
          return rs.map((r) => {
            const { userapp } = r;
            const UserCourse: Student = new Student(userapp, r);
            return UserCourse;
          });
        })
      );
  }

  getFromSubjectGroupIds(ids: number[]): Observable<Array<Student>> {
    const filter = {
      where: { SubjectGroupID: { inq: ids } },
      include: {
        userapp: [
          'ContactInfos',
          'ProfesionalProfile',
          {
            ClassificationUser: [
              'typeBiologicalSex',
              'typeCivilStatus',
              'typeEthnicalGroup',
              'typeIndigeniusPeople',
              'typeCommunities',
              'typeHandicapped',
              'typeExceptionalCapacity',
              'typeZone',
              'typeSisben',
              'typeEstrato',
              'typeRH',
            ],
          },
          { AcademicInfos: 'TypeCourse' },
          { UserDocuments: 'TypeDocuments' },
          { MilitarInfos: ['Range', 'Force'] },
        ],
      },
    };

    return this._httpService
      .httpGet(`/userSubjects?filter=` + JSON.stringify(filter))
      .pipe(
        take(1),
        map((rs: any) => {
          if (!rs || rs.length == 0) return [];
          return rs.map((r) => {
            //clean data
            const { userapp } = r; ///Take userData}
            //  delete r.userapp;
            const UserCourse: Student = new Student(userapp, null, r);

            return UserCourse;
          });
        })
      );
  }

  moveStudentsToGroup(data: Array<updateStudentGroup>): Observable<any> {
    if (!data || data.length == 0) return of(null);
    let url = `UserCourses/UpdateGroup`;
    let usercoursesData = { usercoursesData: data };
    return this._httpService.httpPost(url, usercoursesData).pipe(take(1));
  }

  patchUserCourses(data: object): Observable<any> {
    let url = `UserCourses`;
    return this._httpService.httpPatch(url, data).pipe(take(1));
  }

  createNewUserCourses(data: object): Observable<any> {
    let url = `UserCourses`;
    return this._httpService.httpPost(url, data).pipe(take(1));
  }

  getUserCourses(filter: string): Observable<any> {
    let url = `UserCourses?filter=${filter}`;
    return this._httpService.httpGet(url).pipe(take(1));
  }

  moveStudentsToSubjectGroup(data: Array<updateStudentGroup>): Observable<any> {
    if (!data || data.length == 0) return of(null);
    let url = `UserSubjects/UpdateGroup`;
    let userSubjectsData = { userSubjectsData: data };
    return this._httpService.httpPost(url, userSubjectsData).pipe(take(1));
  }

  enrollSubjects(
    subjectGroupIDs: Array<string>,
    userCourseOferID: number
  ): Observable<any> {
    if (!subjectGroupIDs || subjectGroupIDs.length == 0 || !userCourseOferID)
      return of(null);
    let url = `UserMatriculas/enrollSubject`;
    let data = {
      subjectGroupIDs: subjectGroupIDs,
      userCourseOferID: userCourseOferID,
    };
    return this._httpService.httpPost(url, data).pipe(take(1));
  }

  getMyRoles(): Observable<RoleMapping[]> {
    let url: string = `Userapps/me/roleMappings?filter={"include": ["school","role"]}`;
    return this._httpService.httpGet(url);
  }

  updateUserCourse(id: number,data: any): Observable<any> {
    if (!data || data.length == 0) return of(null);
    let url = `UserCourses/${id}`;
    return this._httpService.httpPatch(url, data).pipe(take(1));
  }

  updateUserDocument(id: number,data: any): Observable<any> {
    if (!data || data.length == 0) return of(null);
    let url = `UserDocuments/${id}`;
    return this._httpService.httpPatch(url, data).pipe(take(1));
  }


  //Update user
  updateUserDocuments(userId: number, recordId:number, data: any,): Observable<any> {
    return this._httpService.httpPut( `Userapps/${ userId }/UserDocuments/${recordId}`, data ).pipe( take( 1 ) );
  }

  updateUserCellphone(userId: number, recordId:number, data: any,): Observable<any> {
    return this._httpService.httpPut( `Userapps/${ userId }/ContactInfos/${recordId}`, data ).pipe( take( 1 ) );
  }

  updateUser( id: number, data: any ): Observable<any> {
    return this._httpService.httpPatch( `Userapps/${ id }`, data ).pipe( take( 1 ) );
  }

  //==

  NewDocumentsInfoSuports(file: File) {
    let url: string = `attachments/documentsinfo/upload`;
    return this._httpService.httpPostFile(url,file);
  }

  getUser(filter?:string):Observable<any>{
    return this._httpService.httpGet(`Userapps${filter ? '?filter=' + filter : ''}`);
  }

  getContactInfo(filter?:string):Observable<any>{
    return this._httpService.httpGet(`ContactInfos${filter ? '?filter=' + filter : ''}`);
  }

  getUserDocument(filter?:string):Observable<any>{
    return this._httpService.httpGet(`UserDocuments${filter ? '?filter=' + filter : ''}`);
  }


/*   getTotalAccepted(courseOfferId:number, isAccepted:boolean){
    const filters = JSON.stringify({
      and:[{ CourseOferID: courseOfferId}, {IsAcepted: isAccepted}]
    });

    return this._httpService.httpGet(`UserCourses/count?where=${filters}`)
  } */
}
