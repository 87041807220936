import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private token: string;
  private userID: number;

  constructor() { }

  getToken() {
    let token = localStorage.getItem('accessToken');
    this.token = null;
    if (token) {
      this.token = token;
    }
    return this.token;
  }

  getUserId() {
    let userID = parseInt(localStorage.getItem('currentUser'));
    this.userID = null;
    if (Number.isInteger(userID)) {
      this.userID = userID;
    }
    return this.userID;
  }

}
