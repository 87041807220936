import { NgModule } from '@angular/core';

import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule, Routes } from '@angular/router';
import { StudentsCoursesPageComponent } from './manage-students-courses-page.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { StudentCardComponent } from 'src/app/shared/components/students/student-card/student-card.component';
import { ManageStudentsListComponent } from 'src/app/shared/components/students/manage-students-list/manage-students-list.component';
import { StudentListComponent } from 'src/app/shared/components/students/student-list/student-list.component';
import { ExchangeStudentsComponent } from 'src/app/shared/components/students/exchange-students/exchange-students.component';
import { BackgroundForceDirective } from 'src/app/shared/directives/background-force.directive';
const routes: Routes = [{ path: '', component: StudentsCoursesPageComponent }];

@NgModule({
  declarations: [
    StudentCardComponent,
    StudentListComponent,
    ManageStudentsListComponent,
    ExchangeStudentsComponent,
    StudentsCoursesPageComponent,
  ],
  imports: [SharedModule, DragDropModule, RouterModule.forChild(routes)],
})
export class StudentsCoursesPageModule {}
