import { Directive, HostBinding, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Directive({
  selector: '[backgroundForce]',
})
export class BackgroundForceDirective {
  @Input() bColor: string = 'lightgrey';
  @Input() bgForceId: number = 0;

  constructor(private sanitizer: DomSanitizer) {}

  @HostBinding('style.background-image')
  get backgroundImage() {
    if (!this.bgForceId || this.bgForceId === 0) return;
    return this.sanitizer.bypassSecurityTrustStyle(
      `url(${`https://storage.googleapis.com/assets_cedoc/schools/forces/${this.bgForceId}.png`}`
    );
  }

  @HostBinding('style.borderColor')
  get color() {
    return this.bColor;
  }

  @HostBinding('style.background-repeat')
  get repeatBackground() {
    return 'no-repeat';
  }
}
